// exports.baseUrl = "http://localhost:3001/api";
// exports.serverUrl = "http://localhost:3001";

exports.baseUrl = "https://be.dev.mazata.in/api";
exports.serverUrl = "https://be.dev.mazata.in";

// exports.baseUrl = "https://be.qty.mazata.in/api";
// exports.serverUrl = "https://be.qty.mazata.in";

// exports.baseUrl = "https://be.app.mazata.in/api";
// exports.serverUrl = "https://be.app.mazata.in";

exports.fileUrl = "/uploads";
exports.DocumentName = "Documents.zip";
exports.Document = "Documents";

exports.AWS_PUBLIC = "https://nodetrial.s3.ap-south-1.amazonaws.com/";
exports.DOCUMENTS_FOLDER = "tailorsDesigners/";

// Zone Admin
exports.zoneAdminUrl = "/admin";

exports.zoneArea = "/zone-area"
exports.zoneAreaStatus = "/activate/"
exports.getzone = "/get-zone";

// zone Admin Create
exports.zoneAdminCreate = "/create";
exports.zoneAdminUpdate = "/update/";
exports.zoneAdminRemove = "/remove/";

exports.zoneAdminActivate = "/activate/";

// Admin login
exports.loginUrl = "/login";
exports.tokenVerifyUrl = "/token-verify";

// Assign Zone
exports.assignZone = "/assign-zone/";
exports.removeZone = "/remove-zone/";

exports.assinedZone = "/assigned-zones/";
exports.assinedTailors = "/assigned-tailors/";


// Assisgn Tailor
exports.assignTailor = "/assign-tailor/";
exports.removeTailor = "/remove-tailor/";

exports.subAttr = "/category";

exports.categoryActivate = "/status/";

exports.subCate = "/sub-category";
// Sub-Category Insert
exports.insSub = "/insert-subcategory"
exports.updateSub = "/update"
exports.deleteSub = "/delete"
exports.insertSub = "/insert"

// Sub-category Attr
exports.subAttrCreate = "/attr/create/";
exports.subAttrUpdate = "/attr/update/";
exports.subAttrStatus = "/attr/status/";
exports.subAttrGetId = "/attrId/";
exports.subAttrGet = "/attr";

// Sub-Sub Category
exports.Sub_baseSubCategory = "/sub-subcategory";
exports.SubSubStatusChange = "/status-change";

// Measurement Attr
exports.MeasurementAttrUrl = "/measurement-attributes";
exports.MeasureAttrStatusChange = "/status-change";
exports.MeasureAttrAssignAttr = "/assign-attribute";
exports.MeasureAttrRemoveAttr = "/remove-attribute";

exports.CategoryCancelPolicy = "/category-cancel-policy";
exports.CategoryAssignPolicy = "/assign-policy/assign-category";
exports.CategoryAssignedPolicy = "/assign-policy/assigned-category";
exports.CategoryRemovePolicy = "/assign-policy/remove-category";
exports.CategoryCancelActivatePolicy = "/activate";
exports.CategoryCancelFullList = "/get-full-assign-policy/get-full";

exports.AssignedAttrUrl = "/assigned-attribute";

// Style Attributes
exports.StyleAttributesUrl = "/style-attributes";
exports.StyleAttributesStatusChange = "/status-change";

//change password
exports.changePasswordUrl = "/site-admin/changepassword";

//profile
exports.zoneUrl = "/zone";
exports.zoneProfile = "/profile";
exports.zoneUpdate = "/update";

// Zone Admin
exports.zone_AdminUrl = "/zone-admin";
exports.zone_AdminLogin = "/login";
exports.zone_AdminStatus = "/status";
exports.zone_AdminPassword = "/changePassword";

// Tailor Assistant
exports.tailor_assistantUrl = "/tailor-assistant";
exports.tailor_assign_roleUrl = "/assign-role/multiple/mea-del";
exports.notAssigned = "/tailors/area-assigned";
exports.assignTailorArea = "/assign";
exports.removeTailorArea = "/remove";
exports.tailor_assistantStatus = "/status";

// Tailor & Designers
exports.tailorDesignersUrl = "/tailor-designer/admin";
exports.tdStatusActivateUrl = "/active-status";
exports.tdOrderActivateUrl = "/order-status";
exports.tdProfileUpdateUrl = "/profile-update";
exports.tdfileUploadUrl = "/file-upload";
exports.tailorDesignersIonicUrl = "/tailor-designer/ionic";
exports.changePasswordUrl = "/forget-password";
exports.TDstateList = "/state-list/fetch-data/fullstate";

// Campaign
exports.campaignUrl = "/campaign-management";
exports.campaignStatus = "/active";
exports.campaignDelPhoto = "/del-photo";
exports.separatePromoCode = "/insert-promo-code";
exports.hierarchyCreate = "/hierarchy-create";
exports.hierarchyEdit = "/hierarchy-edit";
exports.hierarchySelected = "/hierarchy-selected";

exports.colourUrl = "/colour-palette";
exports.colourStatusUrl = "/activate";

exports.customerUrl = "/customer";
exports.customerStatusUrl = "/activate";

// Products
exports.productUrl = "/admin/trending-design/products";
exports.productConfigUrl = "/admin/trending-design/products";
exports.productStatusUrl = "/status-product";
exports.variantDetailsUrl = "/variant-details";
exports.TailoringServiceStatusUrl = "/update/tailoring-service-status";
exports.BiddingStatusUrl = "/update-bidding/upload-own-design";
exports.productAttrStatusUrl = "/status-product-attr";
exports.productAttributesUrl = "/get-attributes";
exports.productAttributeUrl = "/get-attribute";
exports.editproductAttrUrl = "/edit-product-attr";
exports.editproductUrl = "/edit-product";
exports.delproductUrl = "/del-photo";
exports.feaproductUrl = "/featureProduct";
exports.UploadDesignUrl = "/get-upload-own-design";
exports.ProposalUrl = "/get-proposal-details";
exports.TailoringServiceUrl = "/get-tailoring-services";

exports.promocodeUrl = "/promo-code";
exports.promocodeStatus = "/change-status";

exports.taxApiUrl = "/tax";
exports.taxApiStatusUrl = "/activate";

exports.AgeApiUrl = "/age-category";
exports.AgeApiStatusUrl = "/activate";

exports.customerSupportUrl = "/admin-customer-support";
exports.customerSupportUrlFilter = "/filter";

exports.financeUrl = "/financetrack";

exports.StateApiUrl = "/state";
exports.StateApiStatusUrl = "/activate";

exports.AddSizeurl = "/standard-size";
exports.StandardSizeStatusUrl = "/activate";

exports.MeasurementSizeUrl = "/measurement-size";
exports.MeasurementSizeStatusUrl = "/activate";
exports.MeasurementSizeAssignUrl = "/assign-attribute";
exports.MeasurementSizeAssignedUrl = "/assigned-attribute";
exports.MeasurementSizeRemoveUrl = "/remove-attribute";
exports.MeasurementSizeGetActive = "/get-active/full-list";

exports.AddressApiUrl = "/user-address/fetch-admin";

//Delivery Charge
exports.deliveryCharge = "/delivery-cost";
exports.dcStatusChange = "/status/change";

// config
exports.config = "/config-data";

// Order
exports.adminRouter = "/admin-order";
exports.adminRouterGetlist = "/get-product/filter";
exports.adminFullReturnList = "/get-order-list/return-order-full/full-list";
exports.adminFullReturnrefundList = "/get-order-list/return-order-full";
exports.unassignTailorAssistant = "/unAssign/TailorAssistant";
exports.assignTailorAssistant = "/Assign/TailorAssistant";
exports.changeStatusCommon = "/changeStatus/commonApi";
exports.changeStatusCommon = "/changeStatus/commonApi";
exports.changeReturnOrderStatusAPI = "/return-order/change-status/accept-reject";

//getSlot
exports.slotUrl = "/admin/slot/get-slot"
exports.slotrefreshUrl = "/admin/slot/refresh-slot"
exports.slotChangeStatus = "/change-status"

//User type
exports.gc_mz_siteAdmin = 1;
exports.gc_mz_zoneAdmin = 2;
exports.gc_mz_tailorDesigner = 3;
exports.gc_mz_tailorAssistants = 4;
exports.gc_mz_customers = 5;
